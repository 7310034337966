import './App.css';
import React, { useState } from 'react';
import DOMPurify from 'dompurify';
import NavbarComponent from './content/navcomponent';
import { SloganComponent } from './content/SloganComponent';
import { FooterComponent } from './content/FooterComponent';
import {handler, useHandler} from './handler'

const App = () => {
  // const [htmlContent, setHtmlContent] = useState(null);
  const handleChange = ({ target: { name, value } }) => 
    setInputData(prevState => ({ ...prevState, [name]: value }));
  const [inputData, setInputData] = useState({ CompanyName: '', Description: '' });
  
  const [navbarContent, setNavbarContent] = useState(''); // Fixed state declaration
  const [mainContent, setMainContent] = useState('');  
  const [footer, setFooter] = useState('');  

  const { handleSubmit,loading } = useHandler();
   
  const handleFormSubmit = () => {
    // handleSubmit('post','http://aitest.maldonado.xyz:5000/navbar', inputData, setNavbarContent);
    handleSubmit('post','http://aitest.maldonado.xyz:5000/content', inputData, setMainContent);
    // handleSubmit('post','http://aitest.maldonado.xyz:5000/footer', inputData, setFooter);
  };
  if( mainContent ){
    return(
    <div className={inputData.CompanyName}>
        <SloganComponent setHtmlContent={DOMPurify.sanitize(mainContent)}/>
    </div>
    );
  }

  return (
    <div className="App co">

      <div>
        <ul>
          {['CompanyName', 'Description'].map(field => (
            <li key={field}>
              {field}: 
              <input 
                type="text" 
                name={field} 
                placeholder={field} 
                value={inputData[field]} 
                onChange={handleChange} 
              />
            </li>
          ))}
        </ul>
      
        <button onClick={handleFormSubmit} disabled={loading}>
        {
          loading   ?  
          'Generating....' : 'Submit'
        }</button>
      </div>
    </div>
  );
};

export default App;
