import { useState } from 'react';
import axios from 'axios';
import { act } from 'react-dom/test-utils';

export const useHandler = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = async (type ,url, data, onSuccess) => {
    setLoading(true);
    setError(null);
    var response = null;
    try {
      console.log(`http ${type} url:${url} with ${data}`)
      if(type === 'get'){
         response = await axios.get(url, data);
      }else if (type === 'post'){
         response = await axios.post(url, data);
      }


      if (response.data) {
        onSuccess(response.data);
      }
    } catch (err) {
      setError(err);
      console.error('Error:', err);
      setLoading(false)
    } finally {

    }
  };

  return { handleSubmit, loading, error };
};
